import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["display", "select"];

  connect() {
    // Ajoute un listener pour le changement de sélection
    this.selectTarget.addEventListener("change", this.updateDisplay.bind(this));
    this.updateDisplay(); // Met à jour la transcription au chargement initial si une option est sélectionnée
  }

  disconnect() {
    // Nettoie le listener lors de la déconnexion du contrôleur
    this.selectTarget.removeEventListener("change", this.updateDisplay.bind(this));
  }

  updateDisplay() {
    console.log("Updating display...");
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex];
    const transcription = selectedOption.dataset.transcription || "Sélectionnez une transcription pour la voir ici.";
    this.displayTarget.innerHTML = transcription;
  }
}
